import { ref } from "vue";
import { defineStore } from "pinia";
import {
  type CalendarCheckDto,
  type CheckDto,
  CheckService,
  type CreateCheckRequest,
  type DeleteApiCheckByIdResponse,
  type GetApiCheckByIdResponse,
  type GetApiCheckCalendarResponse,
  type GetApiCheckListResponse,
  type ListCheckDto,
  type UpdateCheckRequest,
} from "@/client";
import { convertTimeToDateTime } from "@/utils";
import { getInputTypeDropdownOptions } from "@/interfaces/InputTypes";

export const useCheckStore = defineStore("check", () => {
  const inputTypes = getInputTypeDropdownOptions();
  const checkList = ref([] as ListCheckDto[]);
  const calendar = ref([] as CalendarCheckDto[]);

  async function getCheckCalendar(start: string, end: string) {
    return await CheckService.getApiCheckCalendar({
      from: start,
      to: end,
    })
      .then((response: GetApiCheckCalendarResponse) => {
        calendar.value = response;
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function getCheck(id: string) {
    return await CheckService.getApiCheckById({ id })
      .then((response: GetApiCheckByIdResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function getChecks() {
    return await CheckService.getApiCheckList({ page: 0, pageSize: 1000 })
      .then((response: GetApiCheckListResponse) => {
        if (response.items != undefined) {
          checkList.value = response.items;
        }
        return Promise.resolve();
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function createCheck(check: CheckDto) {
    const insert: CreateCheckRequest = {
      description: check.description,
      name: check.name,
      tasks: check.tasks,
      dueTime: convertTimeToDateTime(check.dueTime)?.toISOString(),
      recurranceRule: check.recurranceRule,
      icon: check.icon,
      recurring: check.recurring,
      canBeFinishedMultipleTimes: true,
      isEnabled: check.isEnabled,
    };
    return await CheckService.putApiCheck({ requestBody: insert })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function updateCheck(check: CheckDto) {
    const update: UpdateCheckRequest = {
      id: check.id,
      description: check.description,
      name: check.name,
      tasks: check.tasks,
      dueTime: convertTimeToDateTime(check.dueTime)?.toISOString(),
      recurranceRule: check.recurranceRule,
      icon: check.icon,
      recurring: check.recurring,
      canBeFinishedMultipleTimes: check.canBeFinishedMultipleTimes,
      isEnabled: check.isEnabled,
    };

    return await CheckService.postApiCheck({ requestBody: update })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function deleteCheck(id: string) {
    return await CheckService.deleteApiCheckById({ id })
      .then((response: DeleteApiCheckByIdResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  return {
    getCheckCalendar,
    getChecks,
    getCheck,
    createCheck,
    updateCheck,
    deleteCheck,
    

    inputTypes,
    checkList,
    calendar,
  };
});
