<script setup lang="ts">
    import {onBeforeMount, ref, watch, computed } from "vue";
    import { storeToRefs } from "pinia";
    import {useRoute, useRouter} from "vue-router";
    import { useUserStore, useLocationsStore, useNotificationStore } from "@/stores/";
    import Badge from 'primevue/badge';
    import Dropdown, { type DropdownChangeEvent } from 'primevue/dropdown';
    import { isInRole } from "@/utils";
    import { ROLES } from "@/config";
    import Sidebar from 'primevue/sidebar';
    import OverlayPanel from "primevue/overlaypanel";
    
    const emit = defineEmits(["releaseNotes"]);
    const appversion = ref(import.meta.env.VITE_APP_VERSION);
    const userStore = useUserStore();
    const locationStore = useLocationsStore();
    const notificationStore = useNotificationStore();
    const { locationId } = storeToRefs(userStore);
    const { locations } = storeToRefs(locationStore);
    const router = useRouter();
    const route = useRoute();
    const currentPage = ref(route.path);
    const notificationsVisible = ref(false);
    const op = ref();
    const notificationCount = computed(() => notificationStore.notifications.filter(x => x.seenOn == null).length);
    var routes = computed(() => 
        router.getRoutes().filter((r:any) => 
        r.meta.inMenu && 
        r.meta.roles.some((x:string) => isInRole(x)) &&
        (r.meta.multiLocation == undefined || (r.meta.multiLocation == true && locations.value.length > 1)))
    );
    const isSmall = ref(false);
    window.addEventListener('resize', () => {
        if (window.innerWidth < 768) {
            isSmall.value = true;
        } else {
            isSmall.value = false;
        }
    });
    const toggleSmallLocationSelect = (event:any) => {
        op.value.toggle(event);
    }
    
    const changeLocation = (lid: DropdownChangeEvent) => {
        if (userStore.decodedAuth == null) return;
        userStore.decodedAuth!.lid = lid.value;
        userStore.setLocation(lid.value).then(() => {
            window.location.reload();
        });
    }
    const logout = () => {
        userStore.logout();
    }
    const showNotifications = () => {
        notificationsVisible.value = true;
    }
    const close = () => {
        notificationStore.setSeen();
        notificationsVisible.value = false;
    }

    onBeforeMount(() => {
        if (userStore.isAuthenticated) {
            notificationStore.list();
        }
        if (window.innerWidth < 768) 
            isSmall.value = true;
    });

    watch(route, (to, from) => {
        currentPage.value = to.path;
    });

    const showReleaseNotes = () => {
        emit("releaseNotes");
    }
</script>

<template> 
    <aside v-if="userStore.isAuthenticated" id="mainmenu" class="fixed top-0 left-0 z-40 w-small md:w-64 h-screen transition-transform translate-x-0 md:translate-x-0 bg-white shadow-lg">
        <div class="flex flex-col h-full">
            <div class="text-center mt-3" :class="{'hidden': isSmall}">
                <div class="flex">
                    <img class="mx-auto h-12 w-auto" src="@/assets/logo.png" alt="CheckMate">
                    <div class="relative p-3 mr-1 relative">
                        <Badge :value="notificationCount" severity="danger" v-if="notificationCount > 0" @click="showNotifications" class="p-overlay-badge fixed right-0 mr-1" />
                        <i class="fa fa-bell" style="font-size: 2rem" @click="showNotifications" />
                    </div>
                </div>
                <div>{{ userStore.tenant }}</div>
            </div>
            <div class="relative" :class="{'hidden': !isSmall}">
                <Badge :value="notificationCount" severity="danger" v-if="notificationCount > 0" @click="showNotifications" class="p-overlay-badge fixed right-2" />
                <img src="@/assets/icon.jpg" alt="CheckMate" class="mx-auto h-12 w-auto" />
            </div>
            <div class="text-center p3 mt-3 px-4"  v-if="locations.length > 1">
                <Dropdown
                    v-if="!isSmall"
                    filter
                    :options="locations.map((s:any) => ({text: s.name, value: s.id})).sort((x,y) => x.text.localeCompare(y.text))"
                    v-model="locationId"
                    optionLabel="text"
                    optionValue="value"
                    class="w-full" 
                    @change="changeLocation"
                />
                <div v-if="isSmall">
                    <span class="text-xs line-clamp-2" :title="locations?.find(x => x.id == locationId)?.name" @click="toggleSmallLocationSelect">
                        {{ locations?.find(x => x.id == locationId)?.name }}
                    </span>
                    <OverlayPanel ref="op">
                        <div>
                            <div>Location:</div>
                            <div>
                                <Dropdown
                                    filter
                                    :options="locations.map((s:any) => ({text: s.name, value: s.id})).sort((x,y) => x.text.localeCompare(y.text))"
                                    v-model="locationId"
                                    optionLabel="text"
                                    optionValue="value"
                                    class="w-full" 
                                    @change="changeLocation"
                                />
                            </div>
                        </div>
                    </OverlayPanel>
                </div>
            </div>
            <div class="-auto mt-4">
                <ul class="list-none m-0" :class="{'px-1': isSmall, 'px-4': !isSmall}">
                    <li v-for="route in routes" v-bind:key="route.name">
                        <router-link 
                            :to="route.path"
                            class="flex items-center cursor-pointer p-1 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" 
                            :class="{
                                'bg-primary text-white': currentPage===route.path,
                                'text-xs': isSmall,
                                'flex-col': isSmall,
                                'gap-0': isSmall,
                                'p-2': !isSmall,
                                }">
                            <i :title="route.name?.toString()" class="text-lg md:text-base" :class="route.meta.icon"></i>
                            <span >{{route.name}}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="mt-auto">
                <hr class="mb-3 mx-3 border-t-1  border-surface-200 dark:border-surface-700" />
                <ul class="list-none m-0" :class="{'px-1': isSmall, 'px-4': !isSmall}">
                    <li v-if="isInRole(ROLES.CHECKMATE)">
                        <router-link  
                            to="/accounts" 
                            class="flex items-center cursor-pointer p-1 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" 
                            :class="{
                                'bg-primary text-white': currentPage==='/accounts',
                                'text-xs': isSmall,
                                'flex-col': isSmall,
                                'gap-0': isSmall,
                                'p-1': !isSmall,
                            }">
                            <i title="Accounts" class="fa fa-boxes text-lg md:text-base"></i>
                            <span>Accounts</span>
                        </router-link>
                    </li>
                    <li v-if="isInRole(ROLES.CHECKMATE) || isInRole(ROLES.ADMIN) || isInRole(ROLES.OFFICE)">
                        <router-link 
                            to="/locations" 
                            class="flex items-center cursor-pointer p-1 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" 
                            :class="{
                                'bg-primary text-white': currentPage==='/locations',
                                'text-xs': isSmall,
                                'flex-col': isSmall,
                                'gap-0': isSmall,
                                'p-2': !isSmall,
                                }">
                            <i title="Locations" class="fa fa-location-dot text-lg md:text-base"></i>
                            <span>Locations</span>
                        </router-link>
                    </li>
                    <li v-if="isInRole(ROLES.CHECKMATE) || isInRole(ROLES.ADMIN) || isInRole(ROLES.OFFICE)">
                        <router-link 
                            to="/users" 
                            class="flex items-center cursor-pointer p-1 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" 
                            :class="{
                                'bg-primary text-white': currentPage==='/users',
                                'text-xs': isSmall,
                                'flex-col': isSmall,
                                'gap-0': isSmall,
                                'p-2': !isSmall,
                                }"
                            >
                            <i title="Users" class="fa fa-users text-lg md:text-base"></i>
                            <span>Users</span>
                        </router-link>
                    </li>
                    <li v-if="isInRole(ROLES.ADMIN)">
                        <router-link  
                            to="/settings" 
                            class="flex items-center cursor-pointer p-1 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" 
                            :class="{
                                'bg-primary text-white': currentPage==='/settings',
                                'text-xs': isSmall,
                                'flex-col': isSmall,
                                'gap-0': isSmall,
                                'p-2': !isSmall,
                            }">
                            <i title="Settings" class="fa fa-cog text-lg md:text-base"></i>
                            <span>Settings</span>
                        </router-link>
                    </li>
                    <li>
                        <a 
                        target="_blank" 
                        href="https://checkmate.software/training-videos/" 
                        class="flex items-center cursor-pointer p-1 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" 
                        :class="{
                            'text-xs': isSmall,
                            'flex-col': isSmall,
                            'gap-0': isSmall,
                            'p-2': !isSmall
                            }"
                        >
                            <i title="Help" class="fas fa-question-circle text-lg md:text-base"></i>
                            <span>Help</span>
                        </a>
                    </li>
                    <li>
                        <a 
                            href="javascript:void(0)" 
                            @click="logout" 
                            class="flex items-center cursor-pointer p-1 gap-2 rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100 dark:hover:bg-surface-700 duration-200 transition-colors" 
                            :class="{
                            'text-xs': isSmall,
                            'flex-col': isSmall,
                            'gap-0': isSmall,
                            'p-2': !isSmall
                            }">
                            <i title="Logout" class="fas fa-right-from-bracket text-lg md:text-base"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="text-center">
                <small style="font-size: 10px;" @click="showReleaseNotes">Version: {{ appversion }}</small>
            </div>
        </div>

        <Sidebar v-model:visible="notificationsVisible" @hide="close" header="Notification" style="width: 35vw" position="right">
            <div v-for="notification in notificationStore.notifications" :key="notification.createdOn" class="p-3 border-b border-surface-200 dark:border-surface-700">
                <div :class="{'new-notification': notification.seenOn == null}">
                    <router-link  to="/" v-if="notification.category?.toString() == '2'">{{notification.message}}</router-link>
                    <router-link  to="/issues" v-if="notification.category?.toString() == '0'">{{notification.message}}</router-link>
                    <span v-if="notification.category?.toString() == '1'">{{ notification.message }}</span>
                </div>
            </div>
            <div v-if="notificationStore.notifications.length == 0">
                <div class="p-3 text-center">No notifications</div>
            </div>
        </Sidebar>
    </aside>
</template>

<style>
    .new-notification {
        font-weight: bold;
    }


</style>