export const global = {
    emailAddress: "Email Address",
    password: "Password",
    oldpassword: "Old Password",
    newpassword: "New Password",
    firstName: "First Name",
    lastName: "Last Name",
    name: "Name",
    description: "Description",
    company: "Company",
    login: "Login",
    passwordEnter: "Your desired password",
    confirmPassword: "Confirm password",
    continue: "Continue",
    back: "Back",
    schedule: "Schedule",
    tasks: "Tasks",
    label: "Label",
    type: "Type",
    due: "Due",
    phone: "Phone",
    norecords: "No records found",
    termsAndConditions: "I acknowledge that I have read and understand the CheckMate <a style='color: blue' href='https://checkmate.software/terms-and-conditions/' target='_blank'>terms & conditions</a>",
    acceptTermsAndConditions: "You must accept our terms and conditions",
    FreeTrialNoSubscription: "You are currently within your free trial which ends in {date}, create a subscription now to avoid interruption.",
    FreeTrialWithSubscription: "You are currently within your free trial which ends in {date}",
    NoFreeTrialNoSubscription: "Your free trial has ended, create a subscription to avoid interruption.",
    multipletimes: "Check can be done multiple times per day",
    introduction: "Please watch our introduction video for our quick start guide",
    recurrance: {
        repeat: "Schedule",
        last: "Last",
        lastWeekday: "Last Weekday",
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly",
        yearly: "Yearly",
        days: "days",
        weeks: "weeks",
        months: "months",
        years: "years",
        repeatEvery: "Repeat every",
        day: "Day",
        month: "Month",
        onThe: "on the ",
        of: " of ",
        before: "Before",
        monthnames: {
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December"
        },
        weekdays: {
            sunday: "Su",
            monday: "Mo",
            tuesday: "Tu",
            wednesday: "We",
            thursday: "Th",
            friday: "Fr",
            saturday: "Sa"
        }
    },
    fullweekdays: {
        sunday: "Sunday",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday"
    }
    
}