import { useSettingStore } from "@/stores/setting.store";

export enum InputTypes {
    Text,
    TextArea,
    CheckBox,
    FridgeTemp,
    FreezerTemp,
    HotHoldingTemp,
    CookingTemp,
    Signature,
    Select,
    Message,
    Timestamp,
    CoolingTemp,
    HotProbeTemp,
    ColdProbeTemp,
    ColdHoldingTemp,
    RegenerationTemp,
    CheckBoxNo,
    CustomTemperature,
    PhotoTask
}

export const getInputTypeDropdownOptions = () => {
    const settings = useSettingStore();
    return [
        { text: "Text", value: 0 },
        { text: "Text area", value: 1 },
        { text: "Checkbox (Yes Expected)", value: 2 },
        { text: "Checkbox (No Expected)", value: 16 },
        { text: "Fridge Temperature " + settings.getTempRangeString(settings.settings.fridge), value: 3 },
        { text: "Freezer Temperature " + settings.getTempRangeString(settings.settings.freezer), value: 4 },
        { text: "Cooking Temperature " + settings.getTempRangeString(settings.settings.cooking), value: 6 },
        { text: "Cooling Tempeature " + settings.getTempRangeString(settings.settings.cooling), value: 11 },
        { text: "Hot Probe Temperature " + settings.getTempRangeString(settings.settings.hotProbe), value: 12 },
        { text: "Cold Probe Temperature " + settings.getTempRangeString(settings.settings.coldProbe), value: 13 },
        { text: "Cold Holding Temperature " + settings.getTempRangeString(settings.settings.coldHolding), value: 14 },
        { text: "Hot holding Temperature " + settings.getTempRangeString(settings.settings.hotHolding), value: 5 },
        { text: "Regeneration Temperature " + settings.getTempRangeString(settings.settings.regeneration), value: 15 },
        { text: "Custom Temperature", value: 17 },
        { text: "Signature", value: 7 },
        { text: "Dropdown", value: 8 },
        { text: "Message", value: 9 },
        { text: "Timestamp", value: 10 },
        { text: "Take a Photo", value: 18 }
    ];
}

export const getInputTypeTreeOptions = () => {
    const settings = useSettingStore();
    return [{
        key: 'text',
        label: 'Text',
        icon: 'fas fa-font',
        selectable: false,
        children: [
          { key: 0, label: 'Single-line textbox', data: 0 },
          { key: 1, label: 'Multi-line textbox', data: 1 }
        ]
      },
      {
        key: 'checkbox',
        label: 'Checkbox',
        icon: 'fas fa-square-check',
        selectable: false,
        children: [
          { key: 2, label: 'Checkbox (Yes Expected)', data: 2 },
          { key: 16, label: 'Checkbox (No Expected)', data: 16 }
        ]
      },
      {
        key: 'temp',
        label: 'Temperature',
        data: '',
        icon: 'fas fa-thermometer-half',
        selectable: false,
        children:  [
          { key: 3, label: 'Fridge Temperature ' + settings.getTempRangeString(settings.settings.fridge), data: 3 },
          { key: 4, label: 'Freezer Temperature ' + settings.getTempRangeString(settings.settings.freezer), data: 4 },
          { key: 6, label: 'Cooking Temperature ' + settings.getTempRangeString(settings.settings.cooking), data: 6 },
          { key: 11, label: 'Cooling Temperature ' + settings.getTempRangeString(settings.settings.cooling), data: 11 },
          { key: 12, label: 'Hot Probe Temperature ' + settings.getTempRangeString(settings.settings.hotProbe), data: 12 },
          { key: 13, label: 'Cold Probe Temperature ' + settings.getTempRangeString(settings.settings.coldProbe), data: 13 },
          { key: 5, label: 'Hot Holding Temperature ' + settings.getTempRangeString(settings.settings.hotHolding), data: 5 },
          { key: 14, label: 'Cold Holding Temperature ' + settings.getTempRangeString(settings.settings.coldHolding), data: 14 },
          { key: 14, label: 'Regeneration Temperature ' + settings.getTempRangeString(settings.settings.regeneration), data: 15 },
          { key: 17, label: 'Custom Temperature', data: 17 }
        ]
      },
      {
        key: 8,
        label: 'Dropdown',
        data: 8,
        icon: 'fas fa-square-caret-down',
        selectable: true
      },
      {
        key: 7,
        label: 'Signature',
        data: 7,
        icon: 'fas fa-signature',
        selectable: true
      },
      /*{
        key: 18,
        label: 'Photo',
        data: 18,
        icon: 'fas fa-camera',
        selectable: true
      },*/
      {
        key: 9,
        label: 'Message',
        data: 9,
        icon: 'fas fa-message',
        selectable: true
      }
      ,
      {
        key: 10,
        label: 'Timestamp',
        data: 10,
        icon: 'fas fa-clock',
        selectable: true
      }
    ];
}

export function isTemperatureInput(type: number | undefined) : boolean {
    if (type === undefined) return false;
    return (type === InputTypes.FridgeTemp || 
        type === InputTypes.FreezerTemp || 
        type === InputTypes.HotHoldingTemp || 
        type === InputTypes.CookingTemp || 
        type === InputTypes.CoolingTemp || 
        type === InputTypes.HotProbeTemp || 
        type === InputTypes.ColdProbeTemp || 
        type === InputTypes.ColdHoldingTemp || 
        type === InputTypes.RegenerationTemp ||
        type === InputTypes.CustomTemperature
    );
}