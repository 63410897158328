import { defineStore } from "pinia";
import { 
  TenantService, 
  UserService, 
  type GetApiTenantExistsByCompanyResponse, 
  type GetApiUserExistsByEmailResponse, 
  type PostApiTenantActivateResponse, 
  type PutApiTenantAdminCreateResponse,
  type GetApiTenantResponse,
  type PutApiTenantResponse,
  type GetApiTenantStatsResponse} from "@/client/";
import {useUserStore} from "@/stores/user.store";
import { event } from 'vue-gtag'


export const useTenantStore = defineStore("tenant", () => {
  async function registerTenant(company:string, firstName: string, lastName: string, email:string, phone: string, password: string) : Promise<PutApiTenantResponse> {
    try {
      return await TenantService.putApiTenant({requestBody: {company: company, firstName: firstName, lastName: lastName, email: email, phone: phone, password: password}})
        .then((response: PutApiTenantResponse) => {
          event('register')
          const userStore = useUserStore();
          return userStore.login(email, password).then(() => {;
            return Promise.resolve(response);
          });
        })
        .catch((error:any) => {
          console.error(error);
          return Promise.reject(error);
        });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function activateTenant(id:string) {
    return await TenantService.postApiTenantActivate({requestBody: {id: id}})
      .then((response: PostApiTenantActivateResponse) => {
        return Promise.resolve(response);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }

  async function checkTenantExists(name:string) {
    return await TenantService.getApiTenantExistsByCompany({company: name})
      .then((response: GetApiTenantExistsByCompanyResponse) => {
        return Promise.resolve(response);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }

  async function checkEmailExists(email:string) {
    return await UserService.getApiUserExistsByEmail({email: email})
      .then((response: GetApiUserExistsByEmailResponse) => {
        return Promise.resolve(response);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }

  async function getAll() {
    return await TenantService.getApiTenant()
      .then((response: GetApiTenantResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function quickCreateNew(company:string, firstName:string, lastName:string, email:string, password: string) {
    return await TenantService.putApiTenantAdminCreate({requestBody: {company: company, firstName: firstName, lastName: lastName, email: email, password}})
      .then((response: PutApiTenantAdminCreateResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function getTenantStats() {
    return await TenantService.getApiTenantStats()
      .then((response: GetApiTenantStatsResponse) => {
        return Promise.resolve(response);
      });
  }


  return {
    registerTenant,
    activateTenant,
    checkTenantExists,
    checkEmailExists,
    getAll,
    quickCreateNew,
    getTenantStats
  };
});
