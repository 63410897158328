export default [
    {
        "title": "Custom Temperature Task",
        "description": "We've added a new task type for adding custom temperature ranges to a temperature check. This is useful for tasks that require non-standard temperature ranges such as room temperature checks.",
        "image": ""
    },
    {
        "title": "General Issues On Report",
        "description": "Issues that are reported on the app, but not attached to a specific check are now shown on the daily report.",
        "image": ""
    },
    {
        "title": "Help link",
        "description": "We have now uploaded some training videos to help you get started with CheckMate. You can access these videos from the new help link in the menu.",
        "image": ""
    },
    {
        "title": "Grouped Task Types",
        "description": "The task types dropdown was getting a bit unwieldy with so many options, so we've grouped the options into categories to make them easier to choose from.",
        "image": ""
    },
    {
        "title": "Minor Fixes & Improvements",
        "description": "Thanks to all our users who have reported issues and suggested improvements. We've made a number of minor fixes and improvements to make CheckMate better for everyone.",
        "image": ""
    }
]