<script lang="ts" setup>
import { ref, onBeforeMount, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import Menu from "@/components/layout/menu.vue";
import Toast from "primevue/toast";
import { useLocationsStore, useSubscriptionStore, useUserStore } from "@/stores/";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmDialog from "primevue/confirmdialog";
import { formatDate, formatToRelativeTime } from "./utils";
import Message from "primevue/message";
import i18n from "@/i18n";
import Dialog from "primevue/dialog";
import { Button } from "@/components/base";
import SubTitle from "./components/base/SubTitle.vue";
import releaseNotes from "@/releaseNotes";


const appversion = ref(import.meta.env.VITE_APP_VERSION);
const locations = useLocationsStore();
const subscriptionStore = useSubscriptionStore();
const userStore = useUserStore();
const loaded = ref(false);
const isInTrial = ref(true);
const hasSubscription = ref(false);
const route = useRoute();
const router = useRouter();
const introductionDialogVisible = computed(() => userStore.user != null && userStore.user.loginOption == 3);
const releaseNotesDialogVisible = ref(userStore.user != null && userStore.user.loginOption == 4);
const showMenu = () => {
  const menu = document.querySelector("#mainmenu") as HTMLElement;
  menu.classList.toggle("translate-x-0");
};
const trialmessageType = computed(() => {
  if (subscriptionStore.subscription != undefined) {
    if (new Date(subscriptionStore.subscription.trialEndDate!) < new Date()) {
      if (subscriptionStore.subscription.isSubscribed) {
        return ""
      } else if (!subscriptionStore.subscription.isActive) {
        return "expired"
      }
    } else {
      if (subscriptionStore.subscription.isSubscribed) {
        return "subtrial"
      } else {
        return "trial"
      }
    }
    return "";
  }
  if (!hasSubscription.value) {
    return "secondary";
  } else {
    return "error";
  }
});

onBeforeMount(() => {
  if (userStore.isAuthenticated) {
    locations.list();
    subscriptionStore.get().then(() => {
      if (subscriptionStore.subscription) {
        if (subscriptionStore.subscription.subscriptionType != null) {
          hasSubscription.value = true;
        }
        if (new Date(subscriptionStore.subscription.trialEndDate!) < new Date()) {
          isInTrial.value = true;
        } else {
          isInTrial.value = false;
        }
      } else {
        isInTrial.value = false
      }
      loaded.value = true;
    });
  }
});

const sendToSubscription = () => {
  router.push({ name: "Settings" });
}

const updateLoginOptions = () => {
  const userStore = useUserStore();
  userStore.user.loginOption = 0;
  userStore.updateLoginOption(0);
}

</script>

<template>
  <main v-if="route.meta.hideUI">
    <RouterView />
    <Toast />
  </main>
  <main v-else>
    
    <div class="flex sm:flex-col">
      <RouterView v-slot="{ Component }">
        <Menu @release-notes="releaseNotesDialogVisible = !releaseNotesDialogVisible"></Menu>
        <div class="ml-20 ml-small md:ml-64 px-5">
          <Message v-if="loaded && trialmessageType=='trial'" severity="secondary" @click="sendToSubscription()">
            {{ i18n.global.t("global.FreeTrialNoSubscription", {date:formatToRelativeTime(subscriptionStore.subscription?.trialEndDate)}) }}
          </Message>
          <Message v-if="loaded && trialmessageType=='subtrial'" severity="secondary" @click="sendToSubscription()">
            {{ i18n.global.t("global.FreeTrialWithSubscription", {date:formatToRelativeTime(subscriptionStore.subscription?.trialEndDate)}) }}
          </Message>
          <Message v-if="loaded && trialmessageType=='expired'" severity="error" @click="sendToSubscription()">
            {{ i18n.global.t("global.NoFreeTrialNoSubscription", {date:formatToRelativeTime(subscriptionStore.subscription?.trialEndDate)}) }} 
          </Message>
          <div class="pt-5">
            <component :is="Component" />
          </div>
        </div>
      </RouterView>
    </div>

    <Dialog ref="dialogIntroduction" modal v-model:visible="introductionDialogVisible" @update:visible="updateLoginOptions" style="width: 60vw" header="Welcome to CheckMate!">
      <p>Please watch our introduction video for our quick start guide</p>
      <div class="p-5 text-center">
        <video width="100%" controls>
          <source src="/welcome.mp4" type="video/mp4" /> 
        </video> 
      </div>
      <Button @click="updateLoginOptions">Close</Button>
    </Dialog>

    <Dialog modal v-model:visible="releaseNotesDialogVisible" @update:visible="releaseNotesDialogVisible = false" style="width: 40vw" :header="'What&rsquo;s new in version ' + appversion">
      <div class="mb-5" v-for="note in releaseNotes" :key="note.title">
        <h2 class="mb-2 text-lg font-semibold leading-9 tracking-wide text-gray-900">{{ note.title }}</h2>
        <p>{{ note.description }}</p>
      </div>
    </Dialog>

    <Toast />
    <ConfirmPopup />
    <ConfirmDialog group="dialog" />
  </main>
</template>
